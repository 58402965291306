import styles from './styles.module.scss';
import { Background, Parallax } from 'react-parallax';
import classNames from 'classnames';
import { CONTENT } from 'content';


const About = ({ language }) => {
	return (
		<Parallax strength={-100}>
			<Background className={styles.background}>
				<div/>
			</Background>
			<section id='About us' className={styles.about}>
				<div className={classNames(styles.wrapper, 'container')}>
					<div>
						<h2>{CONTENT[language].about.heading}</h2>
						<p>{CONTENT[language].about.description}</p>
					</div>
				</div>
			</section>
		</Parallax>
	);
};

export default About;
