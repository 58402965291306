import { CONTENT } from 'content';
import styles from './styles.module.scss';
import { Parallax, Background } from "react-parallax";
import { forwardRef } from 'react';


const MainSection = forwardRef(({ language }, ref) => (
	<>
		<Parallax
			strength={250}
		>
			<Background className={styles.background}>
				<div/>
			</Background>
			<section id="main" className={styles.main}>
				<div className="container">
					<div className={styles.descriptionWrapper}>
						<h2>{CONTENT[language].main.heading}</h2>
						<p>{CONTENT[language].main.description}</p>
						<button ref={ref} className="trigger button primary">
							{CONTENT[language].main.button}
						</button>
					</div>
				</div>
			</section>
		</Parallax>
	</>
));

export default MainSection;
