import styles from './styles.module.scss';
import { CONTENT } from 'content';
import { Fragment } from 'react';

const Contacts = ({ language }) => {
	const { heading, infoList } = CONTENT[language].contacts;

  return (
	  <section id='Contact' className={styles.contacts}>
		  <div className="container">
			  <div className={styles.wrapper}>
				  <div>
					  <iframe
					  	title='location'
						  width="100%"
						  height="300px"
						  loading="lazy"
						  allowFullScreen
						  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDK2FDOEQ8ZQWeC3yRtCQfkjMMRdCWPZP4&q=Barbershop+SKILL">
					  </iframe>
				  </div>
				  <div>
					  <h1>{heading}</h1>
					  <br/>
					  {infoList.map(({ title, description }, index) => <Fragment key={index}>
						  <h3>{title}</h3>
						  {index === 1 ? <a href={`tel:${description}`}><p>{description}</p></a> : <p>{description}</p>}
					  </Fragment>)}
				  </div>
			  </div>
		  </div>
	  </section>
  );
};

export default Contacts;
