import {useEffect, useMemo, useState} from 'react';
import {CONTENT, LOCALES} from 'content';
import styles from './styles.module.scss';

const url = 'https://docs.google.com/spreadsheets/d/1TIOLiHZpBcRSq0su3ZOZA8-RF8An8e7E4QUf4tt0jeo/gviz/tq?tqx=out:json&tq&gid=0'

const Services = ({ language }) => {
	const { heading } = CONTENT[language].services;
	const priceFormat = useMemo(() => new Intl.NumberFormat(language, { style: 'currency', currency: 'uah', maximumSignificantDigits: 3 }), [language])
	const [serviceList, setServiceList] = useState([])


	useEffect(() => {
		fetch(url, {
			headers: {
				"Content-Type": "application/json",
			},
		}).then(response => response.text())
			.then(text => {
				const jsonMatch = text.match(/google\.visualization\.Query\.setResponse\((.*)\);/);
				const jsonData = jsonMatch[1];
				const parsedData = JSON.parse(jsonData);
				const parsedDataRows = parsedData.table.rows.map((arr) => ({ [LOCALES.EN]: arr.c[0].v, [LOCALES.UA]: arr.c[1].v, price: arr.c[2].v, column: arr.c[3].v }));
				const serviceByColumn = parsedDataRows.reduce((acc, item) => {
					if (!acc[item.column]) {
						acc[item.column] = [];
					}

					acc[item.column].push(item)

					return acc;
				}, [])
				setServiceList(serviceByColumn);
			})
	}, []);

	return (
	  <section id='Services' className={styles.services}>
		  <div className="container">
			  <h1>{heading}</h1>
			  <div className={styles.wrapper}>
				  {serviceList.map((serviceList, index) =>
					  <ul key={index}>
						  {serviceList.map((service) => <li key={service[language]}>{service[language]} / {priceFormat.format(service.price)}</li>)}
					  </ul>
				  )}
			  </div>
		  </div>
	  </section>
  );
};

export default Services;
