import { useEffect, useState } from 'react';
import { CONTENT, LOCALES } from 'content';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { ReactComponent as Facebook } from 'assets/images/facebook.svg'
import { ReactComponent as Instagram } from 'assets/images/instagram.svg'
import { ReactComponent as Barber } from 'assets/images/barber.svg'

const Header = ({ language, onChangeLanguage }) => {
	const [isScrolled, setIsScrolled] = useState(false);

	const scrollListener = () => {
		setIsScrolled(window.pageYOffset > 10);
	};

	useEffect(() => {
		window.addEventListener('scroll', scrollListener)

		return () => window.removeEventListener('scroll', scrollListener)
	}, [])

	const handleScrollToSection = (sectionId) => {
		const section = document.getElementById(sectionId);

		section.scrollIntoView({block: "center", behavior: "smooth"})
	}

	return (
		<header className={classNames({ [styles.scrolled]: isScrolled })}>
			<div className="container">
				<div className={styles.header}>
					<ul className={styles.social}>
						<li><a href="//www.facebook.com/barbershop.skill"><Facebook/></a></li>
						<li><a href="//www.instagram.com/barbershop.skill"><Instagram/></a></li>
					</ul>
					<a href="#main" className={styles.logo}>BARBERSHOP<Barber/>SKILL</a>
					<ul className={styles.languages}>
						{Object.entries(LOCALES)
							.map(([languageName, languageCode]) =>
								<li
									key={languageName}
									className={classNames({ [styles.active]: language === languageCode })}
									onClick={() => onChangeLanguage(languageCode)}
								>
									{languageName}
								</li>)}
					</ul>
				</div>
				<ul className={styles.menu}>
					{CONTENT[language].header.menu.map((menuItem, index) => <li key={menuItem} onClick={() => handleScrollToSection(CONTENT[LOCALES.EN].header.menu[index])}>{menuItem}</li>)}
				</ul>
			</div>
		</header>
	);
};

export default Header;
