import styles from './styles.module.scss';
import classNames from 'classnames';
import { CONTENT } from 'content';

const Team = ({ language }) => {
	const { heading, list } = CONTENT[language].team;

  return (
    <section id="Team" className={styles.team}>
	    <h1>{heading}</h1>
	    <div className={classNames("container", styles.teamList)}>
		    {list.map(({ img, name }) => <figure key={name}>
			    <img src={img}
			         alt={name} />
			    <figcaption>{name}</figcaption>
		    </figure>)}
	    </div>
    </section>
  );
};

export default Team;
