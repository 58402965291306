import MainSection from 'components/main/MainSection';
import { useEffect, useRef, useState } from 'react';
import { CONTENT, LOCALES } from 'content';
import Services from 'components/services/Services';
import About from 'components/about/About';
import Header from 'components/header/Header';
import Team from 'components/team/Team';
import Contacts from 'components/contacts/Contacts';
import classNames from 'classnames';


function App() {
	const [language, setLanguage] = useState(LOCALES.UA);
	const [isScrolled, setIsScrolled] = useState(false);
	const buttonRef = useRef();

	const scrollListener = () => {
		setIsScrolled(window.pageYOffset >= buttonRef.current?.offsetTop);
	};

	useEffect(() => {
		window.addEventListener('scroll', scrollListener);

		return () => window.removeEventListener('scroll', scrollListener);
	}, []);


	return (
		<>
			<Header
				language={language}
				onChangeLanguage={setLanguage}
			/>
			<MainSection ref={buttonRef} language={language} />
			<Services language={language} />
			<About language={language} />
			<Team language={language} />
			<Contacts language={language} />
			<div className={classNames('button-container', { showed: isScrolled })}>
				<button className="trigger button primary">
					{CONTENT[language].main.button}
				</button>
			</div>
		</>
	);
}

export default App;
